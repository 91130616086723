import React, { useState } from "react";
import { Row, Col, Card, Badge } from "react-bootstrap";

import FormCheck from "./partials/FormCheck";
import FormConfirmation from "./partials/FormConfirmation";
import FormPersonal from "./partials/FormPersonal";
import FormInterested from "./partials/FormInterested";
import Success from "./partials/Success";
import { Player } from '@lottiefiles/react-lottie-player';
import ClosedIcon from 'assets/lottiefiles/closed.json'

const Wizard = ({
  slug,
  expo,
  onHide,
}: {
  slug?: string;
  expo?: any;
  onHide?: any;
}) => {
  const [layout, setLayout] = useState('personal');
  const [leads, setLeads] = useState<any>();
  const [isNewLeads, setIsNewLeads] = useState<boolean>(true);

  if(!expo){
    return <></>
  }

  return (
    <>
      <section className="section pb-0 pb-sm-auto mt-4 mt-sm-5" style={{background: '#f5f6f8'}}>
        <div className="container-fluid">
          <Row className="justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-8">
              <Card className="py-3">
                <Card.Body>
                  {
                    expo?.status == 'Closed' ? (
                      <div className="text-center">
                        <h4>We're sorry</h4>
                        <h3>This event has been closed</h3>
                        <div className="my-n4">
                          <Player
                            autoplay
                            keepLastFrame
                            src={ClosedIcon}
                            style={{ height: '300px', width: '300px' }}
                          /> 
                        </div>
                        <p className="mb-0">Please find our upcoming events on the link below</p>
                        <a href="https://event.jackstudy.co.id">https://event.jackstudy.co.id</a>
                      </div>
                    ) : (
                      <>
                        <div className="row d-flex justify-content-start flex-nowrap ps-2">
                          <div className="col-auto px-0 d-none d-lg-block">
                            <div className="d-flex flex-column justify-content-center align-items-center h-100 me-2">
                              <img src={expo.image} width={64}/>
                              <Badge className="font-10 mt-1">#{expo.code}</Badge>
                            </div>
                          </div>
                          <div className="col">
                            <h3 className="my-0">{expo.name}</h3>
                            <div className="row">
                              <div className="col-auto">
                                <div className="d-flex align-items-center">
                                  <strong><i className="mdi mdi-timer-outline me-1 font-18"/></strong>
                                  <span className="">{expo.start_time} - {expo.end_time}</span>
                                </div>
                              </div>
                              <div className="col-auto">
                                <div className="d-flex align-items-center">
                                  <strong><i className="mdi mdi-calendar me-1 font-18"/></strong>
                                  <span className="">{expo.date}</span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-auto">
                                <div className="d-flex align-items-center">
                                  <strong><i className="mdi mdi-google-maps me-1 font-18"/></strong>
                                  <span className="">{expo.venue}</span>
                                </div>
                              </div>
                            </div>
                            {
                              leads?.expo_registrant_code &&
                              <div className="row">
                                <div className="col-auto">
                                  <div className="d-flex align-items-center">
                                    <strong><i className="mdi mdi-code-equal me-1 font-18"/></strong>
                                    <span className="">#{leads?.expo_registrant_code}</span>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                        <hr/>
                        {
                          layout == 'check' &&
                          <FormCheck
                            slug={slug}
                            expo={expo}
                            layout={layout}
                            setLayout={setLayout}
                            leads={leads}
                            setLeads={setLeads}
                            isNewLeads={isNewLeads}
                            setIsNewLeads={setIsNewLeads}
                          />
                        }
                        {
                          layout == 'confirmation' &&
                          <FormConfirmation
                            slug={slug}
                            expo={expo}
                            layout={layout}
                            setLayout={setLayout}
                            leads={leads}
                            setLeads={setLeads}
                            isNewLeads={isNewLeads}
                            setIsNewLeads={setIsNewLeads}
                          />
                        }
                        {
                          layout == 'personal' &&
                          <FormPersonal
                            slug={slug}
                            expo={expo}
                            layout={layout}
                            setLayout={setLayout}
                            leads={leads}
                            setLeads={setLeads}
                            isNewLeads={isNewLeads}
                            setIsNewLeads={setIsNewLeads}
                          />
                        }
                        {
                          layout == 'interested' &&
                          <FormInterested
                            slug={slug}
                            expo={expo}
                            layout={layout}
                            setLayout={setLayout}
                            leads={leads}
                            setLeads={setLeads}
                            isNewLeads={isNewLeads}
                            setIsNewLeads={setIsNewLeads}
                          />
                        }
                        {
                          layout == 'success' &&
                          <Success
                            slug={slug}
                            expo={expo}
                            layout={layout}
                            setLayout={setLayout}
                            leads={leads}
                            setLeads={setLeads}
                          />
                        }
                      </>
                    )
                  }
                </Card.Body>
              </Card>
            </div>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Wizard;
